import { styled } from "styled-components";
import theme from "styled-theming";

import { Container as GridContainer } from "style/components/Page";
import { styleHeadingXL } from "style/components/Typography";
import { GUTTER } from "style/grid";
import { topPadOffset } from "style/headerOffsets";
import { MQ } from "style/mediaQueries";

export const Container = styled(GridContainer)`
    column-gap: ${GUTTER.XL};
    display: grid;
    grid-auto-rows: auto;
    grid-template-areas: "heading" "ctas";
    grid-template-columns: repeat(4, [col-start] 1fr);
    padding-bottom: 4.375rem;
    position: relative;

    ${MQ.FROM_M} {
        grid-template-areas: "heading ctas";
        grid-template-columns: repeat(12, [col-start] 1fr);
        padding-bottom: inherit;
        padding-bottom: 6.125rem;
    }

    ${MQ.FROM_XL} {
        padding-bottom: 8.75rem;
    }
`;

export const Heading = styled.h2.attrs<{
    $headerVisible: boolean;
}>(() => ({}))`
    ${styleHeadingXL};

    grid-area: heading;
    grid-column: ${theme("displayOption", {
        Left: "col-start 1 / span 3",
        Right: "col-start 2 / span 3",
    })};
    place-self: start
        ${theme("displayOption", {
            Left: "flex-start",
            Right: "flex-end",
        })};
    margin-bottom: 0;
    margin-top: 0;
    text-align: ${theme("displayOption", {
        Left: "left",
        Right: "right",
    })};

    ${MQ.FROM_M} {
        grid-column: ${theme("displayOption", {
            Left: "col-start 1 / span 8",
            Right: "col-start 5 / span 8",
        })};
    }

    ${MQ.FROM_L} {
        grid-column: ${theme("displayOption", {
            Left: "col-start 1 / span 6",
            Right: "col-start 7 / span 6",
        })};
        position: sticky;
        top: 4.375rem;
        ${topPadOffset};
    }
`;

export const CallToActions = styled.div`
    display: flex;
    flex-wrap: wrap;
    grid-column: col-start 1 / span 4;
    justify-content: flex-start;
    margin-top: 2.1875rem;
    row-gap: 1.75rem;

    ${MQ.FROM_M} {
        grid-column: ${theme("displayOption", {
            Left: "col-start 5 / span 8",
            Right: "col-start 1 / span 8",
        })};
        margin-top: 2.625rem;
        row-gap: 2.625rem;
    }

    ${MQ.FROM_L} {
        grid-column: ${theme("displayOption", {
            Left: "col-start 7 / span 5",
            Right: "col-start 2 / span 5",
        })};
        margin-top: 3.9375rem;
    }

    ${MQ.FROM_XL} {
        grid-column: ${theme("displayOption", {
            Left: "col-start 7 / span 5",
            Right: "col-start 2 / span 5",
        })};
        margin-top: 5.25rem;
        row-gap: 3.5rem;
    }
`;
